  .manager {
    width: 70%;
  }
  @media (max-width: 550px) {
    .manager {
      display: none;
    }
  }
  
  .drag-container {
    border-radius: 0.3rem;
    background-color: hsl(330, 48%, 90%);
  }
  
  .drag-drop-zone {
    height: 2.2rem;
    text-align: center;
    background: transparent;
    border-radius: 0.3rem;
    border: 2px dashed #71284D;
  }
  
  .drag-drop-zone p {
    color: #71284D;
    margin: auto;
    font-family: "Kiona",sans-serif;
    font-weight: 600;
    font-size: .8rem;
    padding-top: .4rem;
    margin: 0 1rem;
  }
  
  .drag-drop-zone.inside-drag-area {
    opacity: 0.7;
  }